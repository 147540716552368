module.exports.theme = {
  bg: '#F4F5F0',
  darkMuted: '#736355',
  dark: '#52331B',
  compAccentMuted: '#fbada1',
  compAccent: '#e1897c',
  accentMuted: '#b0edb8',
  accent: '#7ce189',
  black: '#141823',
};
