import React from 'react';

export default React.forwardRef((props, ref) => (
  <svg
    ref={ref}
    {...props}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 99.176 99.176"
    height="80px"
    width="80px"
    xmlSpace="preserve"
  >
    <g>
      <path
        d="M57.579,35.783   c-1.627,1.625-1.627,4.282,0,5.909l5.106,5.105c0.534,0.535,0.538,1.42,0,1.959c-0.551,0.551-1.438,0.549-1.972,0.014l-9.598-9.597   c-1.637-1.636-4.273-1.636-5.908-0.001c-1.627,1.627-1.634,4.27,0.002,5.907l7.623,7.623c0.541,0.541,0.533,1.424-0.002,1.959   c-0.549,0.549-1.432,0.557-1.974,0.016L39.933,43.752c-1.625-1.627-4.27-1.635-5.906,0.002c-1.635,1.635-1.627,4.279-0.001,5.904   L44.95,60.584c0.541,0.541,0.536,1.422-0.014,1.971c-0.536,0.537-1.418,0.543-1.959,0.002L17.831,37.411   c-1.635-1.637-4.281-1.627-5.907-0.001c-1.634,1.635-1.634,4.272,0.001,5.907l34.999,34.998L36.759,68.297   c-2.998-0.635-5.925,1.061-6.566,3.803c-0.621,2.748,1.296,5.492,4.279,6.146c0,0,16.876,11.85,23.787,13.27   c6.916,1.436,14.625,0.25,22.939-8.064c13.76-13.758,4.368-25.613,1.193-28.787L63.499,35.771   C61.872,34.144,59.214,34.146,57.579,35.783z"
        stroke="#FFFFFF"
        strokeMiterlimit="10"
        fill="transparent"
        strokeWidth="3.4835"
      />
      <path
        d="M57.579,35.783   c-1.627,1.625-1.627,4.282,0,5.909l5.106,5.105c0.534,0.535,0.538,1.42,0,1.959c-0.551,0.551-1.438,0.549-1.972,0.014l-9.598-9.597   c-1.637-1.636-4.273-1.636-5.908-0.001c-1.627,1.627-1.634,4.27,0.002,5.907l7.623,7.623c0.541,0.541,0.533,1.424-0.002,1.959   c-0.549,0.549-1.432,0.557-1.974,0.016L39.933,43.752c-1.625-1.627-4.27-1.635-5.906,0.002c-1.635,1.635-1.627,4.279-0.001,5.904   L44.95,60.584c0.541,0.541,0.536,1.422-0.014,1.971c-0.536,0.537-1.418,0.543-1.959,0.002L17.831,37.411   c-1.635-1.637-4.281-1.627-5.907-0.001c-1.634,1.635-1.634,4.272,0.001,5.907l34.999,34.998L36.759,68.297   c-2.998-0.635-5.925,1.061-6.566,3.803c-0.621,2.748,1.296,5.492,4.279,6.146c0,0,16.876,11.85,23.787,13.27   c6.916,1.436,14.625,0.25,22.939-8.064c13.76-13.758,4.368-25.613,1.193-28.787L63.499,35.771   C61.872,34.144,59.214,34.146,57.579,35.783z"
        stroke="#FFFFFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="transparent"
        strokeMiterlimit="10"
      />
      <path
        clipRule="evenodd"
        d="M21.597,16.406c-1.064,2.578-4.611,7.087-0.412,8.452   c4.701,1.528,9.213,0.332,13.502,0.929c0.975-0.889,1.217-2.357,0.311-2.99c-0.447-0.514-0.894,0.053-1.34,0.104   c-3.436-0.188-6.872,0.336-10.307-0.619c4.357-5.956,11.238-11.539,17.934-12.367c3.355-0.418,8.207,0.045,10.926,1.854   c1.281,0.852,1.475,2.324,3.094,2.682c0.857,0.18,1.168-1.521,1.235-2.268c-2.73-3.281-6.338-4.63-9.688-4.948   c-8.086-0.761-16.188,3.183-22.984,10.821c0.532-2.885,2.258-5.77,3.504-8.659c-0.275-0.718,0.043-1.441-0.721-2.163   C23.705,9.282,23.022,12.954,21.597,16.406L21.597,16.406z"
        stroke="#FFFFFF"
        fill="transparent"
        fillRule="evenodd"
      />
    </g>
  </svg>
));
